import {
  getClientErrorObject,
  isDefined,
  SupersetClient,
  t,
  tn,
} from '@superset-ui/core';
import { Dispatch } from 'redux';
import {
  addDangerToast,
  addWarningToast,
} from 'src/components/MessageToasts/actions';
import rison from 'rison';
import {
  ActionType,
  Comment,
  CommentDashboardState,
  PostCommentContext,
  RisonSchema,
  WSActionType,
} from '../components/Comments/types';
import {
  RootState,
  DashboardPermalinkState,
  DashboardLayoutState,
  LayoutItem,
} from '../types';
import {
  COMMENTS_PAGE_SIZE,
  SORT_COMMENTS_PARAMS,
} from '../components/Comments/constants';
import { getDashboardUrlParams } from '../../utils/urlUtils';
import { cleanDataMask } from '../components/Comments/utils';
import { CHART_TYPE } from '../util/componentTypes';

export const ADD_DASHBOARD_COMMENT = 'ADD_DASHBOARD_COMMENT';
export const DELETE_DASHBOARD_COMMENT = 'DELETE_DASHBOARD_COMMENT';
export const EDIT_DASHBOARD_COMMENT = 'EDIT_DASHBOARD_COMMENT';
export const READ_DASHBOARD_COMMENT = 'READ_DASHBOARD_COMMENT';
export const READ_ALL_DASHBOARD_COMMENTS = 'READ_ALL_DASHBOARD_COMMENTS';
export const GO_TO_PAGE = 'GO_TO_PAGE';
export const APPLY_EXTRA_FILTERS = 'APPLY_EXTRA_FILTERS';
export const APPLY_MENTION_FILTER = 'APPLY_MENTION_FILTER';
export const APPLY_AUTHOR_FILTER = 'APPLY_AUTHOR_FILTER';
export const APPLY_CHART_FILTER = 'APPLY_CHART_FILTER';
export const APPLY_SEARCH_FILTER = 'APPLY_SEARCH_FILTER';
export const APPLY_STATE_FILTER = 'APPLY_STATE_FILTER';
export const SET_CONTEXT_VALIDITY = 'SET_CONTEXT_VALIDITY';
export const HYDRATE_COMMENTS = 'HYDRATE_COMMENTS';
export const COMMENTS_SET_LOADING = 'COMMENTS_SET_LOADING';
export const SET_ATTACHED_CHART_HIGHLIGHT = 'SET_ATTACHED_CHART_HIGHLIGHT';
export const SET_WS_CONNECTION = 'SET_WS_CONNECTION';
export const DESTROY_WS_CONNECTION = 'DESTROY_WS_CONNECTION';
export const SET_PAGE_INDEX = 'SET_PAGE_INDEX';

/* --- helper functions --- */

export const handleApiError = async (
  response: Response | Error,
  dispatch: Dispatch,
) => {
  const defaultMessage = t('Failed to load comments');
  let message;
  try {
    const error = await getClientErrorObject(response as Response);
    message = error.message || error.error;
  } finally {
    dispatch(addDangerToast(message || defaultMessage));
  }
};

export const getAnchor = (
  dashboardLayout: DashboardLayoutState,
  chartId: number | string | undefined,
) =>
  Object.entries(dashboardLayout.present).find(
    ([, entry]: [string, LayoutItem]) =>
      entry.type === CHART_TYPE &&
      String(entry.meta?.chartId) === String(chartId),
  )?.[0];

const getChartIdAndAnchor = (
  parentComment: Comment | null,
  chartFilter: RisonSchema | undefined,
  dashboardLayout: any,
  saveContext: boolean,
) => {
  let chart_id: string | number | undefined;
  let anchor: string | undefined;

  if (parentComment) {
    chart_id = parentComment.chart_name
      ? parentComment.dashboard_state.slice?.id
      : undefined;

    if ('anchor' in parentComment.dashboard_state?.state) {
      // eslint-disable-next-line prefer-destructuring
      anchor = parentComment.dashboard_state.state.anchor;
    }
  } else if (chartFilter) {
    chart_id = chartFilter.value;
    if (saveContext) {
      anchor = getAnchor(dashboardLayout, chart_id);
    }
  }

  return { chart_id, anchor };
};

/* --- action creators --- */

export const addComment =
  (
    commentInfo: any,
    saveContext = false,
    parentComment: Comment | null = null,
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const newComment = { ...commentInfo };
      const { dashboardComments, dashboardLayout } = getState();
      const { filters } = dashboardComments;

      const chartFilter = filters.find(
        (f: RisonSchema) => f?.opr === 'chart_id',
      );

      const { chart_id, anchor } = getChartIdAndAnchor(
        parentComment,
        chartFilter,
        dashboardLayout,
        saveContext,
      );

      if (chart_id) {
        newComment.chart_id = chart_id;
      }

      if (anchor) {
        newComment.state.anchor = anchor;
      }

      const { response, json } = await SupersetClient.post({
        endpoint: '/api/v1/comment/',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newComment),
      });

      if (response.status !== 201) {
        throw new Error(
          tn(
            'Received unexpected response status (',
            response.status,
            ') while posting comment',
          ),
        );
      }

      if (json.result.warning) {
        dispatch(addWarningToast(json.result.warning));
      }
    } catch (error) {
      handleApiError(error, dispatch);
    }
  };

export const deleteComment =
  (id: number, pageIndex: number) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch({ type: COMMENTS_SET_LOADING, data: true });

      const { dashboardInfo } = getState();
      const { id: dashboardId } = dashboardInfo;

      const { response } = await SupersetClient.delete({
        endpoint: `/api/v1/comment/`,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ comment_ids: [id] }),
      });

      if (response.status !== 200) {
        throw new Error(
          tn(
            'Received unexpected response status (',
            response.status,
            ') while deleting comment',
          ),
        );
      }

      const { response: pageResponse } = await gotoPage(pageIndex, dashboardId);

      if (pageResponse.status !== 200) {
        throw new Error(
          tn(
            'Received unexpected response status (',
            response.status,
            ') while fetching comments',
          ),
        );
      }
    } catch (error) {
      handleApiError(error, dispatch);
    } finally {
      dispatch({ type: COMMENTS_SET_LOADING, data: false });
    }
  };

export const editComment =
  (id: number, content: string) => async (dispatch: Dispatch) => {
    try {
      const { response, json } = await SupersetClient.put({
        endpoint: `/api/v1/comment/${id}`,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ content }),
      });

      if (response.status !== 200) {
        throw new Error(
          tn(
            'Received unexpected response status (',
            response.status,
            ') while editing comment',
          ),
        );
      }

      if (json.result.warning) {
        dispatch(addWarningToast(json.result.warning));
      }
    } catch (error) {
      handleApiError(error, dispatch);
    }
  };

export const loadCommentPage =
  (page = 0, append = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch({ type: COMMENTS_SET_LOADING, data: true });

      const { dashboardComments, dashboardInfo } = getState();

      const { response, json } = await gotoPage(
        page,
        dashboardInfo.id,
        dashboardComments.filters,
      );

      if (response.status !== 200) {
        throw new Error(
          tn(
            'Received unexpected response status (',
            response.status,
            ') while editing comment',
          ),
        );
      }

      dispatch({
        type: GO_TO_PAGE,
        data: {
          comments: append
            ? [...dashboardComments.comments, ...json.result]
            : json.result,
          count: json.count,
        },
      });
    } catch (error) {
      handleApiError(error, dispatch);
    } finally {
      dispatch({ type: COMMENTS_SET_LOADING, data: false });
    }
  };

export const readComment = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: COMMENTS_SET_LOADING, data: true });

    const { response } = await SupersetClient.put({
      endpoint: `/api/v1/comment/read`,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        comment_ids: [id],
      }),
    });

    if (response.status !== 200) {
      throw new Error(
        tn(
          'Received unexpected response status (',
          response.status,
          '). Failed on mark comment as read.',
        ),
      );
    }

    dispatch({
      type: READ_DASHBOARD_COMMENT,
      data: { id },
    });
  } catch (error) {
    handleApiError(error, dispatch);
  } finally {
    dispatch({ type: COMMENTS_SET_LOADING, data: false });
  }
};

export const readAllComments =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: COMMENTS_SET_LOADING, data: true });

    const { dashboardInfo } = getState();

    SupersetClient.put({
      endpoint: `/api/v1/comment/read`,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        dashboard_id_or_slug: dashboardInfo.id,
      }),
    })
      .then(({ response }) => {
        if (response.status !== 200) {
          throw new Error(
            tn(
              'Received unexpected response status (',
              response.status,
              '). Failed on mark all comments as read.',
            ),
          );
        }

        dispatch({
          type: 'READ_ALL_DASHBOARD_COMMENTS',
          data: { unreadComments: [] },
        });
      })
      .catch(() => {
        dispatch(addDangerToast(t('Failed to mark comments as read')));
      })
      .finally(() => {
        dispatch({ type: COMMENTS_SET_LOADING, data: false });
      });
  };

export const hydrateComments =
  (dashboardId: string | number, chartsIds: number[]) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: COMMENTS_SET_LOADING, data: true });

      const {
        response: commentsResponse,
        json: { result: comments, count },
      } = await gotoPage(0, dashboardId, []);

      if (commentsResponse.status !== 200) {
        throw new Error(
          tn(
            'Received unexpected response status (',
            commentsResponse.status,
            ') while loading comments',
          ),
        );
      }

      const {
        response: unreadCommentsResponse,
        json: { result: unreadComments },
      } = await SupersetClient.get({
        endpoint: `/api/v1/comment/?q=${rison.encode({
          filters: [
            {
              col: 'content',
              opr: 'is_read_by_me',
              value: false,
            },
            {
              col: 'id',
              opr: 'dashboard_id_or_slug',
              value: dashboardId,
            },
          ],
        })}`,
        headers: { 'Content-Type': 'application/json' },
      });

      if (unreadCommentsResponse.status !== 200) {
        throw new Error(
          tn(
            'Received unexpected response status (',
            unreadCommentsResponse.status,
            ') while loading comments',
          ),
        );
      }

      let chartComments = {};

      if (chartsIds.length) {
        const {
          response: chartsResponse,
          json: { result },
        } = await SupersetClient.get({
          endpoint: `/api/v1/comment/by_charts?q=${rison.encode({
            // TODO: check for native filters in charts
            charts: chartsIds.filter(chartId => isDefined(chartId)),
            dashboard_id_or_slug: String(dashboardId),
          })}`,
          headers: { 'Content-Type': 'application/json' },
        });

        if (chartsResponse.status !== 200) {
          throw new Error(
            tn(
              'Received unexpected response status (',
              chartsResponse.status,
              ') while loading chart comments',
            ),
          );
        }
        chartComments = result;
      }

      const chartCommentsMap = new Map(
        Object.entries(chartComments).map(([key, value]) => [
          Number(key),
          value,
        ]),
      );

      const ws = getWSConnection(dashboardId, [], dispatch);

      dispatch({
        type: HYDRATE_COMMENTS,
        data: {
          dashboardComments: {
            comments,
            count,
            chartCommentsMap,
            unreadComments,
            ws,
          },
        },
      });
    } catch (error) {
      handleApiError(error, dispatch);
    } finally {
      dispatch({ type: COMMENTS_SET_LOADING, data: false });
    }
  };

export const getWSConnection = (
  dashboardId: string | number,
  filters: RisonSchema[],
  dispatch: Dispatch,
) => {
  const url = `/api/v1/comment/listener/${dashboardId}${
    filters?.length ? `?q=${rison.encode({ filters })}` : ''
  }`;
  const ws = new WebSocket(url);
  ws.onerror = () => {
    ws.close();
  };
  ws.onmessage = event => {
    const data = JSON.parse(event.data);
    const { action_type: actionType, comment } = data;

    switch (actionType) {
      case WSActionType.CREATE: {
        dispatch({
          type: ADD_DASHBOARD_COMMENT,
          data: { comment },
        });
        break;
      }
      case WSActionType.EDIT: {
        dispatch({
          type: EDIT_DASHBOARD_COMMENT,
          data: { comment },
        });
        break;
      }
      case WSActionType.DELETE: {
        dispatch({
          type: DELETE_DASHBOARD_COMMENT,
          data: { id: comment.id },
        });
        break;
      }
      default:
        break;
    }
  };
  return ws;
};

export const gotoPage = async (
  page: number,
  id: string | number,
  extraFilters: RisonSchema[] = [],
  page_size = COMMENTS_PAGE_SIZE,
) => {
  try {
    const { response, json } = await SupersetClient.get({
      endpoint: `/api/v1/comment/?q=${rison.encode({
        page,
        page_size,
        filters: [
          {
            col: 'id',
            opr: 'dashboard_id_or_slug',
            value: id,
          },
          ...extraFilters,
        ],
        ...SORT_COMMENTS_PARAMS,
      })}`,
      headers: { 'Content-Type': 'application/json' },
    });
    if (response.status !== 200) {
      throw new Error(
        `Received unexpected response status (${response.status}) while posting comment`,
      );
    }
    return Promise.resolve({ response, json });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const highlightAttachedChart =
  (dashboardState: CommentDashboardState) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { dashboardLayout } = getState();
    let { anchor } = dashboardState.state as DashboardPermalinkState;
    const chartId = dashboardState.slice?.id;

    let directPathToChild: string[] = [];

    // if the comment was left out of context (!anchor)
    // or deleted (!dashboardLayout.present[anchor]),
    // we look for anchor in redux
    if (!anchor || !dashboardLayout.present[anchor]) {
      anchor = getAnchor(dashboardLayout, chartId) || '';
    }

    if (anchor && dashboardLayout.present[anchor]) {
      directPathToChild = (
        dashboardLayout.present[anchor].parents || []
      ).slice();
      directPathToChild.push(anchor);
    }

    dispatch({
      type: SET_ATTACHED_CHART_HIGHLIGHT,
      data: {
        chartId: dashboardState.slice?.id,
        directPathToChild,
      },
    });
  };

export const removeAttachedChartHighlight = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ATTACHED_CHART_HIGHLIGHT,
    data: {
      chartId: null,
      directPathToChild: null,
    },
  });
};

export const filterCommentsByState =
  (shouldApplyFilter: boolean) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const {
      dashboardInfo,
      dashboardComments,
      dashboardLayout,
      dashboardState,
      dataMask,
    } = getState();

    const { activeTabs } = dashboardState;

    const dashboardId = dashboardInfo.id;

    const chartFilter = dashboardComments.filters.find(
      f => f.opr === 'chart_id',
    );

    let stateFilter = {};

    if (shouldApplyFilter) {
      const urlParams = getDashboardUrlParams();
      const context: PostCommentContext = {
        dashboard_id_or_slug: dashboardId,
        state: {
          activeTabs,
          urlParams,
          dataMask: cleanDataMask(dataMask),
        },
      };

      if (chartFilter) {
        const chartId = parseInt(chartFilter.value as string, 10);
        context.chart_id = chartId;

        const chart_data = Object.entries(dashboardLayout.present).find(
          ([, entry]) =>
            entry.type === CHART_TYPE &&
            String(entry.meta?.chartId) === String(chartId),
        );

        if (chart_data) {
          // @ts-ignore
          context.state.anchor = chart_data[0];
        }
      }

      const jsonString = JSON.stringify(context);
      const encodedJson = encodeURIComponent(jsonString);

      stateFilter = {
        col: 'id',
        opr: 'dashboard_state',
        value: encodedJson,
      };
    }

    dispatch({
      type: APPLY_STATE_FILTER,
      data: shouldApplyFilter ? stateFilter : null,
    });
    // reset WebSocket connection
    const {
      dashboardComments: { ws, filters },
    } = getState();
    ws?.close();
    const newWSConnection = getWSConnection(dashboardId, filters, dispatch);
    dispatch({
      type: SET_WS_CONNECTION,
      ws: newWSConnection,
    });
  };

export const applyExtraCommentFilter =
  ({ type, data }: { type: ActionType; data: RisonSchema | null }) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    // dispatch mention, author, chart or search comment filter
    dispatch({ type, data });
    // reset WebSocket connection
    const {
      dashboardComments: { ws, filters },
      dashboardInfo: { id },
    } = getState();
    ws?.close();
    const newWSConnection = getWSConnection(id, filters, dispatch);
    dispatch({
      type: SET_WS_CONNECTION,
      ws: newWSConnection,
    });
  };

export const destroyWSConnection =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    const { dashboardComments } = getState();
    dashboardComments.ws?.close();
    dispatch({
      type: DESTROY_WS_CONNECTION,
    });
  };

export const setPageIndex = (pageIndex: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PAGE_INDEX,
    pageIndex,
  });
};
