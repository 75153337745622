import { HYDRATE_DASHBOARD } from '../actions/hydrate';
import {
  ADD_DASHBOARD_COMMENT,
  DELETE_DASHBOARD_COMMENT,
  EDIT_DASHBOARD_COMMENT,
  GO_TO_PAGE,
  APPLY_MENTION_FILTER,
  APPLY_AUTHOR_FILTER,
  APPLY_CHART_FILTER,
  APPLY_SEARCH_FILTER,
  APPLY_STATE_FILTER,
  READ_ALL_DASHBOARD_COMMENTS,
  READ_DASHBOARD_COMMENT,
  HYDRATE_COMMENTS,
  COMMENTS_SET_LOADING,
  SET_CONTEXT_VALIDITY,
  SET_ATTACHED_CHART_HIGHLIGHT,
  SET_WS_CONNECTION,
  DESTROY_WS_CONNECTION,
  SET_PAGE_INDEX,
} from '../actions/dashboardComments';
import { UPDATE_DATA_MASK } from '../../dataMask/actions';
import { SET_ACTIVE_TAB } from '../actions/dashboardState';

const updateFilters = (state, filterKey, newFilter) => {
  const filters = state.filters.filter(f => f.opr !== filterKey);
  return newFilter ? [...filters, newFilter] : filters;
};

const isStateFilterApplied = state =>
  state.filters.some(f => f.opr === 'dashboard_state');

export default function dashboardCommentsReducer(state = {}, action) {
  switch (action.type) {
    case HYDRATE_DASHBOARD:
      return {
        ...action.data.dashboardComments,
      };
    case HYDRATE_COMMENTS:
      return {
        ...state,
        ...action.data.dashboardComments,
      };
    case ADD_DASHBOARD_COMMENT: {
      const { comment } = action.data;
      const chartFilterValue = comment.dashboard_state?.slice?.id;

      const update = {
        comments: [comment, ...state.comments],
        count: state.count + 1,
      };

      if (chartFilterValue) {
        const key = Number(chartFilterValue);
        const chartCommentsMap = new Map(state.chartCommentsMap);
        const currentCount = chartCommentsMap.get(key) || 0;
        chartCommentsMap.set(key, currentCount + 1);

        update.chartCommentsMap = chartCommentsMap;
      }

      if (comment.is_read_by_me === false) {
        update.unreadComments = [...state.unreadComments, comment];
      }

      return {
        ...state,
        ...update,
      };
    }
    case DELETE_DASHBOARD_COMMENT: {
      const { id } = action.data;
      const commentToDelete = state.comments.find(comment => comment.id === id);
      const updatedComments = state.comments.filter(
        comment => comment.id !== id,
      );
      const updatedUnreadComments = state.unreadComments.filter(
        comment => comment.id !== id,
      );

      const update = {
        comments: updatedComments,
        unreadComments: updatedUnreadComments,
        count: state.count - 1,
      };

      if (commentToDelete?.dashboard_state?.slice?.id) {
        const key = commentToDelete.dashboard_state.slice.id;
        const chartCommentsMap = new Map(state.chartCommentsMap);
        if (chartCommentsMap.has(key)) {
          const currentValue = chartCommentsMap.get(key);
          if (currentValue > 1) {
            chartCommentsMap.set(key, currentValue - 1);
          } else {
            chartCommentsMap.delete(key);
          }
        }

        update.chartCommentsMap = chartCommentsMap;
      }

      return {
        ...state,
        ...update,
      };
    }
    case EDIT_DASHBOARD_COMMENT: {
      const { comment } = action.data;
      const { id } = comment;
      const commentIndex = state.comments.findIndex(c => c.id === id);
      const unreadCommentIndex = state.unreadComments.findIndex(
        c => c.id === id,
      );

      if (commentIndex === -1) {
        return state;
      }

      const updatedComments = [...state.comments];
      updatedComments[commentIndex] = comment;

      const update = {
        comments: updatedComments,
      };

      if (unreadCommentIndex > -1) {
        update.unreadComments = state.unreadComments.filter(c => c.id !== id);
      }

      return {
        ...state,
        ...update,
      };
    }
    case READ_ALL_DASHBOARD_COMMENTS: {
      const updatedComments = state.comments.reduce((acc, c) => {
        const updatedComment =
          c.is_read_by_me === false ? { ...c, is_read_by_me: true } : c;

        acc.push(updatedComment);
        return acc;
      }, []);

      return {
        ...state,
        unreadComments: action.data.unreadComments,
        comments: updatedComments,
      };
    }
    case READ_DASHBOARD_COMMENT: {
      const { id } = action.data;
      const commentIndex = state.comments.findIndex(c => c.id === id);

      if (commentIndex === -1) {
        return state;
      }

      const updatedComments = state.comments.map((comment, index) =>
        index === commentIndex ? { ...comment, is_read_by_me: true } : comment,
      );

      return {
        ...state,
        unreadComments: state.unreadComments.filter(c => c.id !== id),
        comments: updatedComments,
      };
    }
    case GO_TO_PAGE: {
      return {
        ...state,
        comments: action.data.comments,
        count: action.data.count,
      };
    }
    case APPLY_MENTION_FILTER:
      return {
        ...state,
        filters: updateFilters(state, 'mentioned_id', action.data),
      };
    case APPLY_AUTHOR_FILTER:
      return {
        ...state,
        filters: updateFilters(state, 'author_id', action.data),
      };
    case APPLY_CHART_FILTER:
      return isStateFilterApplied(state)
        ? {
            ...state,
            filters: updateFilters(state, 'chart_id', action.data),
            isContextValid: false,
          }
        : {
            ...state,
            filters: updateFilters(state, 'chart_id', action.data),
          };
    case APPLY_SEARCH_FILTER:
      return {
        ...state,
        filters: updateFilters(state, 'content_text', action.data),
      };
    case APPLY_STATE_FILTER:
      return {
        ...state,
        filters: updateFilters(state, 'dashboard_state', action.data),
        isContextValid: true,
      };
    case UPDATE_DATA_MASK:
      return isStateFilterApplied(state)
        ? {
            ...state,
            isContextValid: false,
          }
        : state;
    case SET_ACTIVE_TAB:
      return isStateFilterApplied(state)
        ? {
            ...state,
            isContextValid: false,
          }
        : state;
    case SET_CONTEXT_VALIDITY: {
      return {
        ...state,
        isContextValid: action.data,
      };
    }
    case COMMENTS_SET_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case SET_ATTACHED_CHART_HIGHLIGHT:
      return {
        ...state,
        highlightedChart: {
          id: action.data.chartId,
          directPathToChild: action.data.directPathToChild,
        },
      };
    case DESTROY_WS_CONNECTION:
      return {
        ...state,
        ws: null,
      };
    case SET_WS_CONNECTION:
      return {
        ...state,
        ws: action.ws,
      };
    case SET_PAGE_INDEX:
      return {
        ...state,
        pageIndex: action.pageIndex,
      };
    default:
      return state;
  }
}
