import { DataMaskStateWithId, SupersetClient, tn } from '@superset-ui/core';
import rison from 'rison';
import { MentionItem } from 'react-mentions';
import getBootstrapData from 'src/utils/getBootstrapData';
import moment, { MomentInput } from 'moment';
import { COMMENTS_PAGE_SIZE } from './constants';
import { User, MentionedUser, UserMention } from './types';
import { isUserAdmin } from '../../util/permissionUtils';

const bootstrapData = getBootstrapData();
export const currentUserId = bootstrapData.user?.userId;
export const currentUser = bootstrapData.user;
export const isAdmin = isUserAdmin(currentUser);

const appContainer = document.getElementById('app');
const { common } = JSON.parse(
  appContainer?.getAttribute('data-bootstrap') || '{}',
);
export const editablePeriod = common?.conf?.COMMENT_EDITABLE_PERIOD_MINUTES;

export const calcPageCount = (count: number, pageSize = COMMENTS_PAGE_SIZE) =>
  Math.ceil(count / pageSize);

export const isCommentEmpty = (content: string) => !content.replace(/\s+/g, '');

export const generateDisplayForUser = (
  user: MentionedUser & User & MentionItem,
): UserMention => ({
  display: user?.display ?? `@${user.first_name} ${user.last_name}`,
  id: Number(user?.user_id ?? user.id),
});

export const updateComment = (text: string, mentionedUsers: UserMention[]) => {
  const regex = /<span data-type="mention">(\d+)<\/span>/g;
  const updatedComment = text.replace(regex, (match, userId) => {
    const user = mentionedUsers.find(
      u => Number(u.id) === parseInt(userId, 10),
    );
    if (user) {
      const mentionMe = user.id === currentUserId ? 'data-mention="me"' : '';

      return `<span data-type="mention" ${mentionMe}>${user.display}</span>`;
    }
    return match;
  });

  return updatedComment;
};

export const commentIsEditable = (time: string | Date) => {
  const inputTime = moment.utc(time);
  const maxEditableTime = inputTime.clone().add(editablePeriod, 'minutes');
  const currentTime = moment();
  return maxEditableTime.isSameOrAfter(currentTime);
};

export const fetchUserOptions = async (
  type: 'author' | 'mention',
  search: string,
  additionalParams: Record<string, any> = {},
) => {
  const query = rison.encode({
    [type]: true,
    search_term: search,
    ...additionalParams,
  });

  const { response, json } = await SupersetClient.get({
    endpoint: `api/v1/comment/users?q=${query}`,
  });

  if (response.status !== 200) {
    throw new Error(
      tn(
        'Received unexpected response status (',
        response.status,
        ') while fetching data',
      ),
    );
  }

  const list: {
    label: string;
    value: string | number;
  }[] = json.result.map((user: User) => ({
    label: `${user.first_name} ${user.last_name}`,
    value: user.id,
  }));

  return {
    data: list,
    totalCount: json.result.count,
  };
};

export const cleanDataMask = (dataMask: DataMaskStateWithId) =>
  Object.keys(dataMask).reduce((acc: any, key: string) => {
    const mask = { ...dataMask[key] };
    const filterState = { ...mask.filterState };
    const filterStateEmpty = Object.values(filterState).every(f => !f);
    if (filterStateEmpty) {
      mask.filterState = {};
    }
    const extraFormDataEmpty = mask.extraFormData?.filters?.length === 0;
    if (extraFormDataEmpty) {
      mask.extraFormData = Object.fromEntries(
        Object.entries(mask.extraFormData ?? {}).filter(
          ([name]) => name !== 'filters',
        ),
      );
    }
    acc[key] = mask;
    return acc;
  }, {});

export const getUtcLocalTime = (time: MomentInput) => moment.utc(time).local();
