import { CommentPreviewConfig, CommentPreviewModeType } from './types';

export const COMMENTS_PAGE_SIZE = 20;

export const SORT_COMMENTS_PARAMS = {
  order_column: 'created_dttm',
  order_direction: 'desc',
};

export const INITIAL_COMMENT_PREVIEW_CONFIG: CommentPreviewConfig = {
  mode: CommentPreviewModeType.NONE,
  comment: null,
};
