import {
  APPLY_AUTHOR_FILTER,
  APPLY_CHART_FILTER,
  APPLY_MENTION_FILTER,
  APPLY_SEARCH_FILTER,
} from 'src/dashboard/actions/dashboardComments';
import { DashboardPermalinkState } from 'src/dashboard/types';

export type User = {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
};

export type UserMention = {
  id: number | string;
  display: string;
};

export type MentionedUser = {
  is_read: boolean;
  id: number;
  user_id: number;
  first_name: string;
  last_name: string;
  username: string;
};

export type CommentDashboardState = {
  dashboard: {
    dashboard_title: string;
    id: number;
  };
  id: number; // this is state id
  slice?: {
    // this is chart
    id: number;
    slice_name: number;
  };
  state: DashboardPermalinkState | {};
};

// get response
export type Comment = {
  author: User;
  chart_name?: string;
  content: string;
  created_dttm: string | Date;
  edit_dttm: string | Date;
  dashboard_name?: string;
  dashboard_state: CommentDashboardState;
  id: number;
  mentioned_users: MentionedUser[];
  is_read_by_me: boolean | null;
};

export type PostCommentContext = {
  chart_id?: number;
  dashboard_id_or_slug: string | number | null;
  state: DashboardPermalinkState | {};
};

export type PostComment = {
  chart_id?: number;
  dashboard_id_or_slug: string | number | null;
  content: string;
  state: DashboardPermalinkState | {};
};

export type CommentsPermissions = {
  canWriteComments: boolean;
  canEditComments: boolean;
  canManageComments: boolean;
};

export type DashboardComments = {
  comments: Comment[];
  count: number;
  unreadComments: Comment[];
  filters: RisonSchema[];
  permissions: CommentsPermissions;
  chartCommentsMap: Map<number, number>;
  isLoading: boolean;
  isContextValid: boolean;
  highlightedChart: { id: number; directPathToChild: string[] };
  ws: WebSocket | null;
  pageIndex: number;
};

export type CommentsResponse = {
  result: Comment[];
  count: number;
  users: User[];
};

export type HandleChangeFunc = (event: { target: { value: string } }) => void;

export type EditorProps = {
  handleChange: HandleChangeFunc;
  handleSubmit: () => void;
  handleEdit: () => void;
  isSubmitting: boolean;
  value: string;
  onChangeSaveContext: () => void;
  shouldSaveContext: boolean;
};

export type FooterProps = {
  isSubmitting: boolean;
  handleSubmit: () => Promise<void>;
  handleEdit: () => Promise<void>;
  handleChange: HandleChangeFunc;
  value: string;
  onChangeModeCancel: () => void;
  footerRef: React.RefObject<HTMLDivElement>;
  onChangeSaveContext: () => void;
  shouldSaveContext: boolean;
};

export type CommentCardProps = {
  comment: Comment;
  handleDelete: (id: number) => Promise<void>;
  onChangeModeStart: (comment: Comment, mode: CommentPreviewModeType) => void;
};

export type CollapsibleSidebarProps = {
  width: any;
  commentsOpen: boolean;
  toggleCommentsBar: (visible?: boolean) => void;
  commentBarOffset: number;
};

export type CommentBarProps = {
  commentsOpen: boolean;
  toggleCommentsBar: (visible?: boolean) => void;
  width: string | number;
  activeTabs: string[];
  commentBarOffset: number;
};

export type MentionsOption = {
  value: string;
  label: string;
};

export type CommentsHeaderProps = {
  toggleCommentsBar: (visible?: boolean) => void;
  unreadCommentsCount: number;
};

export type CommentPreviewProps = {
  handleCancel: any;
};

export type CommentContextType = {
  dashboardId: string | number | null;
  activeTabs: string[];
};

export type RisonSchema = {
  col: string;
  opr: string;
  value: string | number;
};

export enum CommentPreviewModeType {
  REPLY = 'REPLY',
  EDIT = 'EDIT',
  NONE = 'NONE',
}

export type CommentPreviewConfig = {
  mode: CommentPreviewModeType;
  comment: Comment | null;
};

export enum WSActionType {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}

export type ActionType =
  | typeof APPLY_AUTHOR_FILTER
  | typeof APPLY_MENTION_FILTER
  | typeof APPLY_CHART_FILTER
  | typeof APPLY_SEARCH_FILTER;
