import { t, isDefined } from '@superset-ui/core';
import { initSliceEntities } from 'src/dashboard/reducers/sliceEntities';
import { getUrlParam } from 'src/utils/urlUtils';
import { getInitialState as getInitialNativeFilterState } from 'src/dashboard/reducers/nativeFilters';
import getLocationHash from 'src/dashboard/util/getLocationHash';
import { URL_PARAMS } from 'src/constants';
import { addWarningToast } from 'src/components/MessageToasts/actions';
import {
  APPLY_CHART_FILTER,
  applyExtraCommentFilter,
  getAnchor,
  loadCommentPage,
} from './dashboardComments';

export const APPLY_CONTEXT = 'APPLY_CONTEXT';

const getFilteredUrlParams = urlParams => {
  const nativeFilterKeyValue = getUrlParam(URL_PARAMS.nativeFiltersKey);

  const filteredUrlParams = new URLSearchParams(
    `native_filters_key=${nativeFilterKeyValue}`,
  );
  // if multiple url params share the same key [["foo", "bar"], ["foo", "baz"]],
  // we just take the first one as in extractUrlParams function
  urlParams.forEach(param => {
    if (!filteredUrlParams.has(param[0])) {
      filteredUrlParams.set(...param);
    }
  });

  return filteredUrlParams;
};

export const applyContext =
  ({ state, chartId, history }) =>
  async (dispatch, getState) => {
    const { location } = history;
    const { dataMask, activeTabs, anchor, urlParams } = state;
    const {
      dashboardState,
      dashboardLayout,
      dashboardInfo,
      dashboardComments,
      charts,
    } = getState();
    /* refactor this later */
    const { metadata } = dashboardInfo;
    const updatedDataMask = { ...dataMask };

    const { filters: commentsFilters } = dashboardComments;

    const isFilteredByAttachedChart = commentsFilters.some(
      filter => filter.opr === 'chart_id' && filter.value === chartId,
    );

    const isFilteredByAnyChart = commentsFilters.some(
      filter => filter.opr === 'chart_id',
    );

    const isAttachedToChart = isDefined(chartId);
    const isAttachedChartPresent =
      isAttachedToChart && Boolean(getAnchor(dashboardLayout, chartId));

    const shouldApplyChartFilter =
      isAttachedChartPresent &&
      ((isAttachedToChart && !isFilteredByAttachedChart) ||
        (!isAttachedToChart && isFilteredByAnyChart));

    // Searches for a focused_chart parameter in the URL to automatically focus a chart
    const focusedChartId = getUrlParam(URL_PARAMS.dashboardFocusedChart);
    let focusedChartLayoutId;
    if (focusedChartId) {
      // Converts focused_chart to dashboard layout id
      const found = Object.values(dashboardLayout.present).find(
        element => element.meta?.chartId === focusedChartId,
      );
      focusedChartLayoutId = found?.id;
      // Removes the focused_chart parameter from the URL
      const params = new URLSearchParams(window.location.search);
      params.delete(URL_PARAMS.dashboardFocusedChart.name);
    }

    const restoredChartAnchor =
      anchor &&
      !dashboardLayout.present[anchor] &&
      getAnchor(dashboardLayout, chartId);

    // find direct link component and path from root
    const directLinkComponentId =
      focusedChartLayoutId ||
      getLocationHash() ||
      restoredChartAnchor ||
      anchor;
    let directPathToChild = [];
    if (
      directLinkComponentId &&
      dashboardLayout.present[directLinkComponentId]
    ) {
      directPathToChild = (
        dashboardLayout.present[directLinkComponentId].parents || []
      ).slice();
      directPathToChild.push(directLinkComponentId);
    }
    if (!anchor && activeTabs.length) {
      const lowLevelTabId = activeTabs.slice(-1).pop();
      directPathToChild = (
        dashboardLayout.present[lowLevelTabId]?.parents || []
      ).slice();
      directPathToChild.push(lowLevelTabId);
    }

    const chartPathRelevant =
      anchor && directPathToChild.length
        ? activeTabs.every(tab => directPathToChild.includes(tab))
        : true;

    if (anchor) {
      const chartDeleted = !directPathToChild.length;
      const chartMoved = directPathToChild.length && !chartPathRelevant;

      if (chartDeleted) {
        dispatch(
          addWarningToast(
            t(
              'The chart saved in this context was removed from this dashboard.',
            ),
          ),
        );
      }
      if (chartMoved) {
        dispatch(addWarningToast(t('Redirecting to the chart')));
      }
    }

    const nativeFilters = getInitialNativeFilterState({
      filterConfig: metadata?.native_filter_configuration || [],
    });

    let chartQueries;

    if (urlParams) {
      const filteredUrlParams = getFilteredUrlParams(urlParams);

      history.push({
        pathname: location.pathname,
        search: filteredUrlParams.toString(),
      });

      chartQueries = {};
      const chartsIds = Object.keys(charts);

      chartsIds.forEach(id => {
        chartQueries[id] = {
          ...charts[id],
          form_data: {
            ...charts[id].form_data,
            url_params: Object.fromEntries(filteredUrlParams.entries()),
          },
          latestQueryFormData: {},
          triggerQuery: true,
        };
      });
    }

    if (shouldApplyChartFilter) {
      dispatch(
        applyExtraCommentFilter({
          type: APPLY_CHART_FILTER,
          data: chartId
            ? {
                col: 'id',
                opr: 'chart_id',
                value: chartId,
              }
            : null,
        }),
      );
      await dispatch(loadCommentPage());
    }

    return dispatch({
      type: APPLY_CONTEXT,
      data: {
        sliceEntities: { ...initSliceEntities, isLoading: false },
        dataMask: updatedDataMask,
        nativeFilters,
        dashboardState: {
          directPathToChild,
          directPathLastUpdated: Date.now(),
          activeTabs:
            chartPathRelevant || !anchor
              ? activeTabs || dashboardState?.activeTabs || []
              : [],
          fullSizeChartId: null,
        },
        dashboardInfo,
        ...(chartQueries && { charts: chartQueries }),
      },
    });
  };
